























































































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import useAttachment from "@/use/attachment";
import useApi from "@/use/api";

export default defineComponent({
  components: {
    MDatePicker: () => import("@/components/molecules/m-date-picker.vue"),
    OAttachments: () => import("@/components/organisms/o-attachments.vue"),
  },
  setup(_, { root }) {
    const {
      fileTypes,
      fileTypesString,
      truncateFileName,
      uploadAttachments,
      downloadAttachment,
      deleteAttachment,
    } = useAttachment({ root });
    const { axiosInstance } = useApi({ root });

    const form = ref<any>(null);

    const state = reactive({
      dialog: false,
      isFormValid: true,
      loading: false,
      leasing: null as null | { [key: string]: any },
      headers: [
        { value: "startDate", text: "Data rozpoczęcia", sortable: false },
        { value: "endDate", text: "Data zakończenia", sortable: false },
        {
          value: "startMileage",
          text: "Przebieg przy rozpoczęciu",
          sortable: false,
        },
        {
          value: "mileage",
          text: "Maksymalny przebieg roczny",
          sortable: false,
        },
        { value: "installment", text: "Rata", sortable: false },
        {
          value: "data-table-expand",
          text: "",
          sortable: false,
          align: "end",
          width: 1,
        },
      ],
      menu: { startDate: false, endDate: false },
      expanded: [],
      uploading: [] as { id: string; file: File }[],
    });

    const model = reactive<{
      startDate: null | string;
      endDate: null | string;
      startMileage: null | number;
      mileage: null | number;
      installment: null | number;
    }>({
      startDate: null,
      endDate: null,
      startMileage: 0,
      mileage: null,
      installment: null,
    });

    const rules: { [key: string]: Array<(v: any) => string | boolean> } = {
      startDate: [(v) => !!v || "Podaj datę rozpoczęcia leasingu"],
      endDate: [
        (v) => !!v || "Podaj datę zakończenia leasingu",
        (v) =>
          !model.startDate ||
          v >= model.startDate ||
          "Data zakończenia nie może być wcześniejsza niż data rozpoczęcia",
      ],
      startMileage: [
        (v) =>
          !!v.toString() ||
          "Podaj przebieg, jaki ma pojazd przy rozpoczęciu leasingu",
        (v) => v >= 0 || "Przebieg nie może być mniejszy niż zero",
      ],
      mileage: [
        (v) => !v || v >= 0 || "Przebieg nie może być mniejszy niż zero",
      ],
      installment: [
        (v) => !!v || "Podaj ratę miesięczną leasingu",
        (v) => v >= 0 || "Rata nie może być mniejsza niż zero",
      ],
    };

    const resetModel = () => {
      model.endDate = null;
      model.mileage = null;

      form.value.resetValidation();
    };

    const fetchLeasing = () => {
      state.loading = true;

      axiosInstance
        .get(`vehicle/${root.$route.params.id}/leasing`)
        .then(({ data }) => {
          state.leasing = data;
        })
        .catch(() => {
          state.leasing = null;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const addLeasing = () => {
      const leasingData = {
        startDate: model.startDate,
        endDate: model.endDate,
        startMileage: model.startMileage,
        mileage:
          typeof model.mileage === "number" && model.mileage >= 0
            ? model.mileage
            : undefined,
        installment: model.installment,
      };

      axiosInstance
        .post(`vehicle/${root.$route.params.id}/leasing`, leasingData)
        .then(() => {
          state.dialog = false;
          resetModel();
          fetchLeasing();
        })
        .catch(console.log);
    };

    const deleteLeasing = (id: string) => {
      state.loading = true;
      axiosInstance
        .delete(`leasing/${id}`)
        .then(fetchLeasing)
        .catch((error) => console.log(error))
        .finally(() => {
          state.loading = false;
        });
    };

    const uploadToExisting = async (id: string, files: File[]) => {
      for (const file of files) {
        state.uploading.push({ id, file });
      }
      uploadAttachments(files).then(({ data }) => {
        axiosInstance
          .post(`leasing/${id}/attachment`, {
            attachment: data.attachments.map((a: { id: string }) => a.id),
          })
          .then(fetchLeasing)
          .finally(() => {
            for (const file of files) {
              state.uploading.splice(state.uploading.indexOf({ id, file }), 1);
            }
          });
      });
    };

    const handleFileInput = (id: string, event: Event) => {
      event.preventDefault();

      let files: File[] = [];

      const input = event.target as HTMLInputElement;
      if (input.files) {
        files = [];
        for (let i = 0; i < input.files.length; i++) {
          const file = input.files[i];
          if (file) files.push(file);
        }
      }

      if (files.length > 0) uploadToExisting(id, files);

      input.value = "";
      if (input.value) {
        input.type = "text";
        input.type = "file";
      }
    };

    const handleDrag = (id: string, event: Event, type: "enter" | "leave") => {
      event.preventDefault();

      if (type === "leave")
        (event.target as HTMLElement)?.classList.remove("hover");
      else (event.target as HTMLElement)?.classList.add("hover");
    };

    const handleDrop = (id: string, event: DragEvent) => {
      event.preventDefault();

      if (event.target)
        (event.target as HTMLElement)?.classList.remove("hover");

      let files: File[] = [];

      if (event.dataTransfer?.items) {
        // Use DataTransferItemList interface to access the file(s)
        files = [];
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (event.dataTransfer.items[i].kind === "file") {
            const file = event.dataTransfer.items[i].getAsFile();
            if (file) files.push(file);
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        files = [];
        const filesLength = event.dataTransfer?.files.length || 0;
        for (let i = 0; i < filesLength; i++) {
          const file = event.dataTransfer?.files[i] as File;
          if (file) files.push(file);
        }
      }

      if (files.length > 0) uploadToExisting(id, files);
    };

    const onSubmit = async () => {
      await form.value.validate();
      if (state.isFormValid) addLeasing();
    };

    onMounted(fetchLeasing);

    return {
      form,
      state,
      model,
      rules,
      fetchLeasing,
      onSubmit,
      handleFileInput,
      handleDrag,
      handleDrop,
      deleteLeasing,
      fileTypes,
      fileTypesString,
      truncateFileName,
      uploadAttachments,
      downloadAttachment,
      deleteAttachment,
    };
  },
});
